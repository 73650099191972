.custom-switch {
  height: 42px !important;
  width: 57px !important;

  .track {
    background: transparent;
    border: 1px solid #707070;
    border-radius: 10px;
  }

  .thumb {
    background: #343434;
    height: 14px;
    width: 14px;
  }

  .switch-base {
    background-color: transparent;
    padding: 15px;

    &:hover {
      background-color: transparent;
    }

    &.checked {
      background-color: transparent !important;
      transform: translateX(28%);

      .thumb {
        background: $color-primary;
      }

      & + .track {
        background-color: transparent;
      }
    }
  }
}
