/* eslint-disable */
* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  padding: 0;
  margin: 0;
}

.flexer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flexer-space-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flexer-space-around {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.TB {
  border: 1px solid red !important;
}

.flexer-start {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.flexer-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.flexer-column {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.span-anchor {
  font-weight: bold;
  cursor: pointer;
  text-decoration: underline;
}

.text-white {
  color: white !important;
}

.flexer-column-start {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}

/* ::-webkit-scrollbar {
  width: 7px;
} */

/* Track */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #939393;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
::-webkit-scrollbar-thumb:window-inactive {
  background: #939393;
}

.main-wrapper {
  margin-left: 300px;
}

@media (max-width: 770px) {
  .main-wrapper {
    margin-left: 0px;
  }
}

.bg-yellow {
  background-color: #ffd10f !important;
  border: none !important;
  color: black !important;
}

.bg-blue {
  background-color: #2db8e8 !important;
  border: none !important;
  color: white !important;
}

.border-blue-btn {
  background-color: white !important;
  border: 1px solid #2db8e8 !important;
  color: #2db8e8 !important;
}

.border-warning-btn {
  background-color: white !important;
  border: 1px solid #eb5a46 !important;
  color: #eb5a46 !important;
}

.text-primary {
  color: #2db8e8;
}

.cursor-pointer {
  cursor: pointer;
}

.log-statistics-header {
  justify-content: center;
}

.breadcrumb-link:hover {
  color: #3f51b5 !important;
  text-decoration: underline;
}

.logs-modal-table {
  max-height: calc(100vh - 210px) !important;
}

.hide-table-border > td {
  border-top: none !important;
}

.error-log-row {
  background-color: rgb(255, 244, 229);
}

.split-btn-divider {
  border-right: 1px solid black !important;
}

.mr-6 {
  margin-right: 50px !important;
}

.icon-muted {
  color: lightgray;
}

.w-85 {
  width: 85%;
}

.w-15 {
  width: 15%;
}

.font-14 {
  font-size: 14px;
}

.color-black {
  color: black !important;
}

.tag-table-container {
  max-height: 250px !important;
}

.icon-badge {
  background-color: #ffd110 !important;
}

.icon-badge-blue {
  background-color: #2db8e8 !important;
}

.w-48 {
  width: 48%;
}

.w-40 {
  width: 40%;
}

.w-60 {
  width: 60%;
}

.w-90 {
  width: 90%;
}

.tools-page-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
}

@media (max-width: 770px) {
  .tools-page-container {
    flex-direction: column;
  }

  .w-48 {
    width: 100%;
  }
}

.error-box-container {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100% !important;
  max-width: 100% !important;
}

.error-box-border {
  border: 1px solid lightgrey;
}

.btn-danger {
  background-color: #d9534f;
  color: white;
}

.bg-green {
  background: #dff0d8;
}

.text-overflow-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.request-text {
  font-weight: 600;
}
@media (max-width: 768px) {
  .request-text {
    font-size: 12px;
  }
}

.w-12 {
  width: 12%;
}

.h-40px {
  height: 40px;
}

.w-80 {
  width: 80%;
}

.w-38 {
  width: 38%;
}

.text-line-through {
  text-decoration: line-through;
}

.text-line-through:hover {
  text-decoration: line-through;
}

.cursor-initial {
  cursor: initial;
}

.link-text-2 {
  cursor: pointer;
  color: #007bff;
}

.link-text-2:hover {
  text-decoration: underline;
}
