@import "./../../base/variables";

.sub-menu {
  background-color: #343434;
  min-width: 225px;
  width: 225px;
  height: 100vh;
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 100;
  margin-left: 60px;

  &.with-expanded-submenu {
    margin-left: 220px !important;
    @media (max-width: 770px) {
      margin-left: 0px !important;
    }
  }

  .menuitem-wrapper {
    border-bottom: 1px solid #202020;
    padding: 10px 0;

    &:hover {
      background-color: transparent;
    }

    &:last-child {
      border-bottom: none;
      bottom: 12px;
      height: 45px;
      position: absolute;
      width: 100%;
    }

    &.name-title {
      background-color: #272727;
      padding-bottom: 15px;
      padding-left: 18px;
      padding-top: 15px;

      .avatar {
        border-radius: 50%;
        height: 30px !important;
        margin-right: 10px;
        width: 30px !important;
        object-fit: cover;
      }

      .name {
        color: #b2b2b2;
        font-size: 1rem;
        font-weight: 600;
        margin: 0;
      }
    }

    &.number {
      background-color: #272727;
      padding: 0;

      .number-text {
        align-items: center;
        display: flex;
        height: 100%;
        justify-content: space-between;
        padding-right: 6px;
        width: 100%;
      }

      span {
        color: #b2b2b2;
        font-size: 14px;
        font-weight: 600;
      }
    }
  }

  .menuitem {
    align-items: center;
    border-left: 7px solid transparent;
    display: flex;
    height: 45px;
    padding: 6px 0 6px 18px;

    &:hover {
      background-color: #444;
    }

    &.active {
      background-color: #202020;
      border-color: $color-primary;
    }

    .icon {
      margin: 0 12px 0 0;

      svg {
        height: 16px;
        width: 16px;

        path {
          fill: #fff;
        }
      }
    }

    .text {
      color: #fff;
      font-size: 14px;
      font-weight: 500;
    }

    &.logout {
      position: absolute;
      width: 100%;

      .icon svg {
        height: 18px;
        width: 18px;
      }
    }
  }
}

.main-app-container {
  .sub-menu {
    height: 97vh !important;
    top: 28px !important;
  }
}

.hide-menu {
  @media (max-width: 770px) {
    display: none;
  }
}

.mobile-sub-menu {
  @media (min-width: 320px) and (max-width: 770px) {
    width: 100% !important;
    margin-left: 0px !important;
  }
}
