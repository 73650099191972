.gray-bg-card-header {
  background-color: #b4b4b4;
}

.blue-bg-card-header {
  background-color: #2db8e8;
}

.no-right-border-radius {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.no-left-border-radius {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.black-bg-card-header {
  background-color: #575656;
}
