.remove-pair-container {
  background-color: #f5f5f5;
  margin-bottom: 10px;
  padding: 15px 10px;
  border-radius: 10px;
}

.warning-text {
  color: #ff8471;
  font-weight: bold;
}

.advance-settings-text {
  color: #2db8e8;
  font-weight: bold;
  cursor: pointer;
}
