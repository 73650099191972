.sync-option-box-container {
  border: 1px solid #e3e3e3;
  width: 25%;
  @media (max-width: 992px) {
    width: 310px;
  }

  .sync-option-box-haeder {
    padding: 10px 20px;
    border-bottom: 1px solid #e3e3e3;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    @media (max-width: 992px) {
      padding: 10px;
    }
  }
}

.height-with-header {
  height: 180px;
}

.normal-height {
  height: 140px !important;
}

.add-more-sync-pairs {
  margin-top: 25px;
  margin-bottom: 50px;
  color: #2db8e8;
  display: flex;
  align-items: center;
  justify-content: center;
}

.more-tags {
  color: #2db8e8;
}

.custom-autocomplete-select-container {
  border: 1px solid #b7b5b5;
  border-radius: 2px;
  width: 100%;

  .custom-autocomplete-input {
    padding-left: 10px !important;
  }

  .MuiInput-underline::before {
    border: none;
  }
}

.select-mc-tag {
  color: #2db8e8;
  padding-left: 10px;
  cursor: pointer;
  padding-bottom: 3px;
}

.border-none {
  border: none !important;
}

.select-wrapper {
  padding: 20px;
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  @media (max-width: 992px) {
    padding: 20px 10px;
  }
}

.mc-tags-group-box-container {
  border: 1px solid #b7b5b5;
  border-radius: 4px;

  .mc-tabs {
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .tab {
      &:nth-child(1) {
        border-right: 1px solid #b7b5b5;
      }
      border-bottom: 1px solid #b7b5b5;
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 2px 0px;
      cursor: pointer;
      opacity: 0.6;
    }

    .disable {
      color: lightgrey;
      opacity: 1;
    }

    .active {
      background-color: #97d6eb;
      opacity: 1;
    }
  }
}

.add-new-sync-pair-mobile-wrapper {
  @media (max-width: 992px) {
    margin: 0px !important;
    width: 100% !important;
  }
}
.content-container {
  @media (max-width: 992px) {
    width: max-content !important;
  }
}

.error-placeholder {
  &::placeholder {
    color: #ff5f0a !important;
    font-weight: 600;
    opacity: 1 !important;
  }
}
