@import "./../base/variables";

.profile-info-dropright {
  .paper {
    background: #fff;
    border: 1px solid #e2e2e2;
    box-sizing: border-box;
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16));
    left: 210px !important;
    overflow: visible;
    padding-bottom: 5px;
    padding-left: 0;
    padding-top: 0;
    position: absolute;
    width: 243px;
    bottom: 6% !important;

    &.collapsed-dropright-menu {
      left: 55px !important;
    }

    &::before {
      background-color: #fff;
      bottom: 30px;
      content: "";
      filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16));
      height: 11px;
      left: -7px;
      position: absolute;
      transform: rotate(45deg);
      width: 11px;
    }

    .menuitem-wrapper {
      border-bottom: 1px solid #e2e2e2;
      padding: 10px 0;

      &:hover {
        background-color: transparent;
      }

      &:last-child {
        border-bottom: none;
      }

      &.name-title {
        background-color: #f8f8f8;
        padding-bottom: 15px;
        padding-left: 18px;
        padding-top: 15px;

        .avatar {
          border-radius: 50%;
          height: 40px !important;
          width: 40px !important;

          span {
            font-size: 20px;
          }
        }

        .name {
          color: #2db8e8;
          font-size: 0.8rem;
          font-weight: 600;
          margin: 0;
        }

        .title {
          color: #2db8e8;
          font-size: 12px;
          font-weight: 400;
        }
      }

      &.number {
        background-color: #f8f8f8;
        padding-left: 18px;

        span {
          color: #2db8e8;
          font-size: 14px;
          font-weight: 600;
        }

        &.addNumberLink {
          background-color: #eb5a46;
          span {
            color: #fff !important;
          }
        }
      }
    }

    .menuitem {
      background-color: #fff;
      display: flex;
      padding: 6px 0 6px 18px;

      &:hover {
        background-color: #f0f0f0;
      }

      .icon {
        margin-bottom: 0;
        margin-left: 0;
        margin-right: 8px;
        color: #000;

        svg {
          height: 16px;
          width: 16px;

          path {
            fill: #2db8e8;
          }
        }
      }

      .text {
        color: #2db8e8;
        font-size: 14px;
        font-weight: 500;
      }

      &.logout {
        width: 100%;

        .icon svg {
          height: 18px;
          width: 18px;
        }
      }
    }
  }
}

.facebook-icon {
  color: #2db8e8;
  font-size: large !important;
  margin-right: 10px;
}

.dropright-settings-icon {
  .avatar {
    border-radius: 50%;
    height: 30px !important;
    width: 30px !important;
    margin-bottom: 5px;
    span {
      font-size: 20px;
    }
  }
}

.dropright-settings-icon-wrapper {
  background: #3a3b3d !important;
  width: 85% !important;
  border-radius: 10px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: flex-start !important;

  .arrow-right-icon {
    position: absolute;
    right: 0px;
  }
}
