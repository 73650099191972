@import "./../../base/variables";

.dashboard-main {
  padding: 26px;

  .welcome {
    padding: 8px;
  }
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
  padding: 0;

  &.dashboard-container {
    margin-left: 250px;

    @media (max-width: 770px) {
      margin-left: 0px;
    }

    @media (min-width: 770px) and (max-width: 1200px) {
      margin-left: 200px;
    }
  }

  .cards-wrapper {
    margin: 0 auto;
    padding: 20px 18px;
    width: 100%;

    .desktop-title {
      color: #131313;
      font-size: 24px;
      margin-bottom: 25px;
    }

    .container {
      display: flex;
      margin: 0 auto;
      width: 100%;
    }
  }
  @media (max-width: 600px) {
    .dashboard-card.large-text {
      padding: 27px 6px !important;
      width: 100%;
    }

    .dashboard-card.welcome-card .card-content {
      h3 {
        text-align: left;
      }

      .for-small-device {
        align-items: center;
        display: flex;
        justify-content: space-between;

        .contained-primary {
          height: 30px;
          width: 70px !important;
        }
      }

      .banner img {
        position: relative !important;
      }
    }

    .welcome-card .card-content .content {
      padding: 15px !important;

      h3 {
        font-size: 22px !important;
      }
    }
  }
  @media (max-height: 600px) {
    .video {
      height: 28vh !important;
    }
  }

  a {
    text-decoration: none;
  }

  .dashboard-card {
    border-radius: 6px;
    box-shadow: 0 -1px 6px rgba(0, 0, 0, 0.19) !important;
    height: 170px;
    overflow: visible;
    position: relative;
    transition: ease-in-out 0.6s;

    &:hover {
      transform: scale(0.95);
    }

    &.welcome-card {
      height: 190px;
      margin-bottom: 20px;
      max-height: 100%;
      padding: 0;

      &:hover {
        transform: none;
      }

      .card-content {
        display: flex;
        .small-text {
          color: #2db8e8;
        }

        .sync-details-wrapper {
          border-bottom: 1px solid $color-border;
        }
      }

      .contained-primary {
        align-items: center;
        border-radius: 4px;
        display: inline-flex;
        height: 40px;
        justify-content: center;
        margin-left: 12px;
        width: 90px;

        .label {
          margin-top: 0;
        }
      }

      .content {
        color: #343434;
        padding: 20px 34px 28px 39px;

        h3 {
          font-size: 24px;
          font-weight: 600;
          margin: 0 0 14px;
        }

        span {
          font-size: 14px;
        }
        @media (max-width: 360px) {
          text-align: center;

          h3 {
            font-size: 18px;
          }

          span {
            font-size: 13px;
          }

          .contained-primary {
            margin-top: 8px;
          }
        }
      }

      .banner {
        position: relative;

        img {
          bottom: -2px;
          max-height: 128%;
          max-width: 100%;
          position: absolute;
          right: -2px;
        }
      }
    }

    &.full {
      align-items: center;
      padding: 24px 37px 24px 27px;

      .card-content {
        align-items: center;
        display: flex;

        .label {
          flex-grow: 1;
          margin-top: 0;
        }
      }
    }

    .declined-symbol {
      color: #ff5454;
      font-size: 26px;
    }

    .fullwidth-symbol {
      color: #fbc02d;
      font-size: 26px;
    }

    .unconfirmed-symbol {
      color: $color-primary;
      font-size: 26px;
    }
  }

  .card-content {
    padding: 0;

    &:last-child {
      padding-bottom: 0;
    }
  }

  .icon {
    align-items: center;
    border-radius: 50%;
    color: #fff;
    display: flex;
    font-size: 16px;
    height: 23px;
    justify-content: center;
    left: 7px;
    position: absolute;
    top: 7px;
    width: 23px;

    &.send-icon {
      background-color: #b5b5b5;
    }

    &.tick-icon {
      background-color: $color-primary;
    }

    &.cross-icon {
      background-color: #ff5454;
    }

    &.help-icon {
      color: #fbc02d;
      font-size: 27px !important;
    }
  }

  .number {
    align-items: center;
    background-color: #b5b5b5;
    border-radius: 50%;
    color: #fff;
    display: flex;
    font-size: 36px;
    height: 70px;
    justify-content: center;
    margin: auto;
    width: 70px;

    &.red {
      background-color: #ff5454;
    }
  }

  .target-service-wrapper {
    text-align: center;

    .label-with-icon {
      svg {
        height: 21px;
        margin-right: 10px;
        width: 21px;

        path {
          fill: $color-primary;
        }
      }
    }
  }

  .video {
    background-image: url("../../../assets/img/landing-page-bg.png");
    height: 30vh;
    max-width: 100%;
    position: relative;
  }

  .non-verified-user-video {
    background-image: url("../../../assets/img/landing-page-bg.png");
    height: 45vh;
    max-width: 100%;
    position: relative;
  }

  .help-button {
    margin-right: auto;
    margin-top: 22px;
    padding-left: 8px;

    .contained-primary {
      background: linear-gradient(to right, #02b414 0%, #bcff01 100%);
      border: 1px solid #e9e9e9;
      height: 36px;
      justify-content: space-between;
      padding: 0 2px 0 12px !important;
      width: 285px;

      &:hover {
        box-shadow: none;
      }

      .label {
        font-size: 16px;
        margin-top: 0;
      }

      .play-icon {
        align-items: center;
        background-color: #fff;
        color: $color-primary;
        display: flex;
        height: 32px;
        justify-content: center;
        width: 57px;
      }
    }
  }

  .footer-cooming-soon {
    left: 60px !important;
  }

  .comming-soon {
    @media (min-width: 960px) {
      border-top: 1px solid $color-border-light;
      display: flex;
      justify-content: flex-end;

      h3 {
        border-bottom: none !important;
      }
    }

    h3 {
      border-bottom: 1px solid $color-border-light;
      color: #b5b5b5;
      font-size: $size-text;
      font-weight: 400;
      padding: 4px 12px;
      text-transform: uppercase;
      @media (max-width: 360px) {
        font-size: 13px;
      }
    }

    .logos {
      align-items: center;
      display: flex;
      padding-left: 26px;

      img {
        max-width: 100%;
      }
    }
  }

  .billing-info-wrapper {
    @media (max-width: 770px) {
      flex-direction: column;
    }
  }
}

.video-modal {
  .paper {
    @media (max-width: 1300px) {
      max-width: 900px;
      min-width: 700px;
    }
    @media (max-width: 1000px) {
      max-width: 600px;
      min-width: 400px;
    }
    @media (min-width: 1300px) {
      max-width: 1000px;
      min-width: 900px;
    }
    @media (max-width: 800px) {
      max-width: 100%;
      min-width: 90%;
    }
    @media (min-width: 1500px) {
      max-width: 1300px;
      min-width: 1200px;
    }
    @media (min-width: 2000px) {
      max-width: 1600px;
      min-width: 1500px;
    }
    @media (min-width: 2500px) {
      max-width: 2200px;
      min-width: 2000px;
    }
  }

  .modal-content {
    padding: 0;

    &:first-child {
      padding-top: 0;
    }
  }

  .backdrop {
    background: #000;
    opacity: 0.4 !important;
  }

  .video-wrapper {
    height: 0;
    padding-bottom: 56.25%;
    padding-top: 0;
    position: relative;
  }

  .video-wrapper iframe {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.video-icon {
  color: #ffd110;
  cursor: pointer;
}

.card1-width {
  width: 100%;
  height: 245px !important;
  @media (max-width: 800px) {
    width: 100%;
  }
}

.card2-width {
  width: 65%;
  @media (max-width: 800px) {
    width: 100%;
  }
  .sync-details-card-content {
    height: 510px;
    @media (max-width: 800px) {
      height: auto;
    }
  }
}

.card2-buttons {
  width: 30% !important;
  @media (max-width: 800px) {
    width: 45% !important;
    margin: 0px !important;
  }
}

.card2-logs-btn {
  background: #2db8e8 !important;
  border: 1px solid #2db8e8 !important;
  color: black !important;
  height: 40px !important;
  width: 30% !important;
  margin-left: 10px !important;
  @media (max-width: 800px) {
    width: 45% !important;
  }
}

.sync-btn-container {
  width: 30%;
  @media (max-width: 800px) {
    width: 45%;
  }
}

.sync-now-btn {
  background-color: #87bd05 !important;
  background: #87bd05;
  width: 100% !important;
  height: 40px;
  text-transform: capitalize !important;
}

.sync-btn-label {
  font-size: 12px;
  color: #000;
}

.low-opacity {
  opacity: 0.6;
}

.card-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 800px) {
    flex-direction: column;
    justify-content: center;
  }
}

.integration-settings-wrapper {
  width: 32%;
  @media (max-width: 800px) {
    width: 100%;
  }
}

.user-note {
  margin-top: 50px;
  font-size: 1.5rem;
  margin-bottom: 20px;
  font-weight: 600;
  align-self: flex-start;
}

.account-activation-card {
  -webkit-box-shadow: 6px 6px 5px 3px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 6px 6px 5px 3px rgba(0, 0, 0, 0.12);
  box-shadow: 6px 6px 5px 3px rgba(0, 0, 0, 0.12);
  width: 55%;
}

.integrations-btn {
  width: 50% !important;
}

.amount {
  color: #2db8e8;
  font-size: 1.2rem;
  font-weight: bold;
}

.billing-button {
  color: #2db8e8;
  border: 1px solid #2db8e8;
  padding: 0px 5px;
  cursor: pointer;
  &:hover {
    color: white;
    border: 1px solid white;
    background-color: #2db8e8;
  }
}

.manage-btn {
  @media (max-width: 1200px) {
    font-size: 10px !important;
  }
}
