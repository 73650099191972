.code-box {
  border: 1px solid #ccc;
  background-color: #f5f5f5;
  padding: 10px 5px;
  margin-top: 10px;
}

.box {
  border: 1px solid #ccc;
  padding: 10px 5px;
  margin-top: 10px;
}

.link-text {
  color: #2db8e8;
  cursor: pointer;
}

.w-45 {
  width: 45%;
}

.authorize-password-field {
  input {
    height: 10px;
    padding-bottom: 15px;
  }
}

.card-inner-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem 3rem !important;
  @media (min-width: 320px) and (max-width: 767px) {
    flex-direction: column;
    padding: 30px !important;
  }
}

.card-content {
  @media (min-width: 320px) and (max-width: 767px) {
    width: 100% !important;
  }
}

.mailchimp-card-content {
  width: 75%;
  @media (min-width: 320px) and (max-width: 767px) {
    width: 100% !important;
  }
}

.mailchimp-card-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem !important;
  @media (min-width: 320px) and (max-width: 767px) {
    flex-direction: column;
    padding: 30px !important;
  }
}
