.search-cotainer {
  height: 35px;
  width: 350px;
}

.input-abdorment-start {
  padding-left: 0px;
}

.input-abdorment-end {
  padding-right: 0px !important;

  .search-text {
    font-size: 16px;
  }
}

.search-btn {
  background-color: #ffd10f !important;
  border-radius: 5px;
  padding: 10px;
  height: 35px;
}

.log-search-fieldset {
  border-radius: 5px !important;
  border: 1px solid grey !important;
}

.log-search-box {
  @media (max-width: 770px) {
    display: flex;
    align-items: center;
  }
}
