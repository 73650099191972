.unresolve-conflicts-container {
  height: 100%;
  .tab-text {
    font-size: 14px;
    color: black !important;
  }

  .unresolve-conflict-table-header {
    td {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 10px;
      padding-bottom: 5px;
      height: 55px;
      width: 100%;
      border-bottom: 1px solid #8a8686;
      border-right: 1px solid #8a8686;

      &:nth-child(-n + 2) {
        background-color: #707070;
        color: white;
      }
    }
  }
  .unresolve-conflict-table-body-row {
    cursor: pointer;
    td {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 10px;
      padding-bottom: 5px;
      height: 55px;
      width: 100%;
      border-bottom: 1px solid #8a8686;
      border-right: 1px solid #8a8686;
      width: 25%;

      &:nth-child(-n + 2) {
        background-color: #707070;
        color: white;
      }
      p {
        text-align: center;
      }
    }
    .arrow-icon {
      position: absolute;
      right: 50%;
    }
  }
}

.active {
  td {
    background-color: #bbebfc !important;
    color: inherit !important;
  }
}

.resolve-conflict-mobile-wrapper {
  @media (max-width: 770px) {
    margin: 0px !important;
    width: 100% !important;
  }
}
.content-container {
  @media (max-width: 770px) {
    width: max-content !important;
  }
}

.hold-contact-table-container {
  tr {
    color: #808080;
    cursor: pointer;
  }
  .hold-contacts-header-container {
    background: #e1e1e1;
    font-size: 12px;
    th {
      padding: 5px 0.75rem;
    }
  }
  .hold-contacts-table {
    max-height: calc(90vh - 310px) !important;
    border-radius: 5px;
  }
  .match-contacts-table {
    max-height: calc(70vh - 310px) !important;
    border-radius: 5px;
  }
  .create-new-contact-btn-container {
    border-top: 1px solid #dee2e6;
  }
  .ignore-contact {
    z-index: 1000;
  }
}

.view-link {
  font-weight: bold !important;
  color: white !important;
  text-decoration: underline;
  &:hover {
    color: white;
  }
}

.view-them-link {
  font-weight: bold;
  color: black;
  text-decoration: underline;
  &:hover {
    color: black;
  }
}

.export-hold-contact-link {
  font-size: 14px;
  font-weight: bold;
  margin-right: 60px;
  text-decoration: none;
}
