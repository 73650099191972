@import "./../base/variables";
@import "../base/base";

.login-page {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  margin: 0 auto;
  padding: 75px 25px;
  @media (min-width: 960px) {
    max-width: 400px;
    padding: 50px 25px 25px;
  }
  @media (max-width: 960px) {
    padding-top: 0;

    .logo-wrapper {
      flex-direction: column;
      margin-bottom: 30px;

      h3 {
        display: block !important;
        margin-bottom: 0;
        margin-left: 0;
      }
    }

    .small-logo {
      width: 80px;
    }

    .contained-primary {
      margin-bottom: 25px !important;
    }
  }

  .text-field {
    border-radius: 0;
    margin-bottom: 4px;
    width: 100%;
  }

  .helper-text {
    margin-right: 0;
    text-align: right;
  }

  .label {
    color: $color-form-label;
    font-size: $size-label;
  }

  .input-wrapper {
    background-color: $color-surface-light;

    &:before {
      border-color: $color-border;
    }
  }

  .input {
    color: $color-input-text;
    font-size: $size-text;
    padding: 23px 12px 10px;
  }

  .contained-primary {
    border-radius: 4px;
    margin-bottom: 90px;
    // margin-top: 10px;

    .label {
      font-size: $size-button;
    }
  }

  .text-primary {
    width: 100%;

    &:hover {
      background-color: transparent;
    }

    .label {
      font-size: $size-button;
      font-weight: 600;

      .light {
        font-weight: 400;
      }

      .forgot-pw {
        margin-bottom: 14px;
      }
    }
  }

  .text-end {
    width: 100%;
    align-items: flex-end;
    justify-content: flex-end;

    &:hover {
      background-color: transparent;
    }

    .label {
      font-size: $size-button;
      font-weight: 600;

      .light {
        font-weight: 400;
      }

      .forgot-pw {
        margin-bottom: 14px;
      }
    }
  }
  @media (min-width: 960px) {
    .contained-primary {
      float: right;
      margin-bottom: 50px;
      width: 100px;

      .label {
        font-size: $size-button;
      }
    }
  }
}

.success {
  background-color: #acd66c;
  color: #fff;
  padding: 4px 10px;

  &.center {
    text-align: center;
  }
  &.mb {
    margin-bottom: 10px;
  }
}
.error {
  background-color: #e24e4e;
  color: #fff !important;
  padding: 4px 10px;

  h5 {
    color: #fff !important;
  }

  &.center {
    text-align: center;
  }
  &.mb {
    margin-bottom: 10px;
  }
}

.auth-banner {
  background-image: url("../../assets/img/landing-page-bg.png");
  width: 50%;
  overflow: hidden;
  height: 100vh;
  @media (min-width: 320px) and (max-width: 767px) {
    display: none;
  }
}

.term-privacy {
  font-size: 0.7rem;

  .text-link {
    color: blue;
    cursor: pointer;
    font-weight: bold;
  }
}

.input-label {
  font-weight: 600;
  margin-top: 10px;
  font-size: 0.8rem;
}

.input-box {
  height: 50px;
  padding-bottom: 15px;
}

.account-active-btn {
  width: 25% !important;
}
