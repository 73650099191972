.sync-list-container {
  background: linear-gradient(
    to right,
    #707070 0%,
    #707070 50%,
    white 50%,
    white 100%
  );

  .sync-list-table-header {
    td {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-left: 15px;
      height: 45px;
      width: 100%;
      border-bottom: 1px solid #e3e3e3;
      border-right: 1px solid #e3e3e3;

      &:nth-child(-n + 3) {
        border-bottom: 1px solid #8a8686;
        border-right: 1px solid #8a8686;
        color: white;
      }
    }
  }

  .sync-list-table-ccb-mc-header {
    td {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-left: 15px;
      height: 45px;
      width: 100%;
      border-bottom: 1px solid #e3e3e3;
      border-right: 1px solid #e3e3e3;

      &:nth-child(-n + 2) {
        border-bottom: 1px solid #8a8686;
        border-right: 1px solid #8a8686;
        color: white;
      }
    }
  }

  .sync-table-body-ccb-mc-row {
    font-size: 12px !important;

    td {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-left: 30px;
      padding-top: 10px;
      padding-bottom: 5px;
      height: 60px;
      border-bottom: 1px solid #e3e3e3;
      border-right: 1px solid #e3e3e3;
      width: 25%;

      .list-item-name {
        font-size: 14px;

        .broken-text {
          color: #ff5f0a;
          font-weight: 500;
        }
      }

      &:nth-child(-n + 2) {
        border-bottom: 1px solid #8a8686;
        border-right: 1px solid #8a8686;
        color: white;
      }
    }

    .w-22 {
      width: 22.5% !important;
    }

    .w-5 {
      width: 5% !important;
    }

    &:hover {
      background: rgba(163, 221, 240, 0.2);
    }
  }

  .sync-table-body-row {
    font-size: 12px !important;

    td {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-left: 30px;
      padding-top: 10px;
      padding-bottom: 5px;
      height: 60px;
      border-bottom: 1px solid #e3e3e3;
      border-right: 1px solid #e3e3e3;
      width: 25%;

      .list-item-name {
        font-size: 14px;

        .broken-text {
          color: #ff5f0a;
          font-weight: 500;
        }
      }

      &:nth-child(-n + 3) {
        border-bottom: 1px solid #8a8686;
        border-right: 1px solid #8a8686;
        color: white;
      }
    }

    .w-22 {
      width: 22.5% !important;
    }

    .w-5 {
      width: 5% !important;
    }

    &:hover {
      background: rgba(163, 221, 240, 0.2);
    }
  }
}

.broken-sync-pair {
  background-color: rgba(255, 95, 10, 0.07);
  &:hover {
    background: rgba(255, 95, 10, 0.07) !important;
  }
}

.active {
  td {
    background-color: #bbebfc !important;
    color: inherit !important;
  }
}

.resolve-conflict-mobile-wrapper {
  @media (max-width: 770px) {
    margin: 0px !important;
    width: 100% !important;
  }
}
.content-container {
  @media (max-width: 770px) {
    width: max-content !important;
  }
}

.hold-contact-table-container {
  tr {
    color: #808080;
    cursor: pointer;
  }
  .hold-contacts-header-container {
    background: #e1e1e1;
    font-size: 12px;
    th {
      padding: 5px 0.75rem;
    }
  }
  .hold-contacts-table {
    max-height: calc(90vh - 310px) !important;
    border-radius: 5px;
  }
  .match-contacts-table {
    max-height: calc(70vh - 310px) !important;
    border-radius: 5px;
  }
  .create-new-contact-btn-container {
    border-top: 1px solid #dee2e6;
  }
}

.MuiListSubheader-root {
  font-weight: bolder !important;
  color: black !important;
  position: absolute !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  width: 100% !important;
  font-family: "Roboto", Arial, Helvetica, sans-serif !important;
}
