.syncing-progress-bar-container {
  position: fixed;
  // background-color: #202020;
  width: 100vw;
  // padding: 15px 20px;
  z-index: 110;
  top: 0;

  .syncing-progress-bar-wrapper {
    background-color: #bbebfc;
    padding: 3px 30px;
    // border-radius: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .text-black {
    color: black;
  }

  .title {
    color: #2db8e8;
  }

  .cancel-sync {
    color: #ff5f0a;
    cursor: pointer;
  }

  .sync-progress {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(85vw - 200px);

    @media (max-width: 1000px) {
      width: calc(80vw - 200px);
    }
    @media (max-width: 775px) {
      width: calc(70vw - 200px);
    }
  }
}
