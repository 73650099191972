.card-container {
  background: linear-gradient(#ffc70d 0%, #22c1c3 100%);
  border-radius: 5px;
  color: white;
  padding-top: 30px;
  position: relative;
  width: 370px;
  max-width: 100%;
  text-align: center;
}

.avatar-upper-container {
  border: 1px solid #575656;
  border-radius: 50%;
  height: 110px;
  width: 110px;
}

.card-container .pro {
  color: #ffd110;
  background-color: #575656;
  border-radius: 5px;
  font-size: 14px;
  font-weight: bold;
  padding: 3px 7px;
  position: absolute;
  top: 30px;
  left: 30px;
}

.card-container .round {
  border-radius: 50%;
  padding: 7px;
  height: 100%;
  width: 100%;
}

button.primary {
  background-color: white;
  border: 1px solid white;
  border-radius: 5px;
  font-weight: 500;
  padding: 10px 25px;
}

button.primary.ghost {
  background-color: transparent;
  color: white;
}

.skills {
  text-align: left;
}

.skills ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.skills ul li {
  font-size: 14px;
}
