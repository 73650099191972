@import "./../../base/variables";

.billing-page {
  background-color: #fff !important;
  box-shadow: none !important;
  margin: 0 !important;
  padding-right: 20px;
  width: auto !important;

  @media (max-width: 770px) {
    padding-right: 0px;
  }

  .sub-menu {
    bottom: 0;
    display: none;
    position: fixed;
    top: 0;
  }

  .desktop-design {
    margin-top: 25px;
    width: 100%;
    padding: 20px;
  }

  .mobile-design {
    display: block;
    margin-bottom: 50px;
    padding-left: 20px;
    padding-top: 40px;

    .desktop-title {
      display: none !important;
    }
  }

  .desktop-title {
    color: #131313;
    font-size: 24px;
    margin-bottom: 25px;
  }

  .starting-package {
    background: linear-gradient(#ffc70d 0%, #22c1c3 100%);
    border-radius: 4px;
    margin-bottom: 30px;
    padding: 13px 15px 14px;
    text-align: center;

    img {
      margin-bottom: 26px;
    }

    .large-text {
      color: #fff;
      font-size: 24px;
      font-weight: 600;
    }

    .small-text {
      color: #fff;
      font-size: 16px;
    }

    .actions {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-top: 22px;
    }

    .text-primary {
      color: #fff;
    }

    .contained-primary {
      align-items: center;
      background-color: #fff;
      border-radius: 4px;
      display: flex;
      height: 24px;
      justify-content: center;
      padding: 0 10px !important;

      .label {
        color: $color-primary !important;
      }
    }
  }

  .add-credits {
    background-color: #fbfbfb;
    border: 1px solid #e4e4e4;
    border-radius: 4px;
    margin-bottom: 30px;
    padding: 17px 13px 21px 17px;

    .large-text {
      color: #343434;
      font-size: 17px;
      font-weight: 700;
    }

    .credits-select {
      background-color: #f2f2f2;
      height: 40px;
      margin-bottom: 27px;
      margin-top: 19px;
      width: 100%;
    }

    .select {
      padding-left: 12px;
    }

    .btn {
      border-radius: 4px;
      height: 26px;
      padding: 0 16px;
    }

    .btn-wrapper {
      text-align: right;
    }
  }

  .auto-recharge {
    background-color: #fbfbfb;
    border: 1px solid #e4e4e4;
    border-radius: 4px;
    padding: 17px 13px 21px 17px;

    .large-text {
      color: #343434;
      font-size: 17px;
      font-weight: 700;
      margin-bottom: 20px;
    }

    .btn {
      border-radius: 4px;
      height: 26px;
      padding: 0 16px;
    }

    .btn-wrapper {
      text-align: right;
    }

    .details {
      color: #343434;
      font-size: 13px;
      margin-bottom: 10px;
      margin-top: -15px;
    }

    .autorecharge-off {
      opacity: 0.3;
    }
  }

  .section-title {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
  }

  .logs-section {
    flex-wrap: nowrap;
    @media (max-width: 450px) {
      flex-wrap: wrap;
    }
  }

  .billing-card {
    margin-bottom: 30px;

    .single {
      align-items: center;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      width: auto;

      .label {
        align-items: center;
        color: #343434;
        display: flex;
        font-size: 12px;
        margin-top: 12px;
        white-space: nowrap;

        .icon {
          font-size: 9px !important;
          height: 13px !important;
          margin: 0 5px 0 auto;
          width: 13px !important;
        }

        svg {
          height: 13px;
          width: 13px;
        }
      }

      .react-sweet-progress-symbol {
        font-size: 24px;

        .text {
          font-size: 10px;
        }

        &:first-child {
          line-height: 0.6;
          text-align: center;
        }
      }
    }
  }

  .table-container {
    box-shadow: none;
  }

  .cards-table {
    border: none;
    @media (max-width: 770px) {
      width: max-content !important;
    }

    .table-head {
      background-color: #f2f2f2;
      border-radius: 5px 0 0 5px;
      max-height: 100% !important;

      tr {
        height: 35px !important;
      }

      th {
        border: none;
        padding: 0 16px;
        font-size: 16px;

        button {
          min-width: 0;
          padding: 0;

          &.on-focus {
            .drop-arrow {
              transform: rotate(180deg);
            }
          }
        }
      }

      .drop-arrow {
        cursor: pointer;
      }
    }

    .table-body {
      tr {
        height: 32px;
        padding: 0;
      }

      td {
        border-bottom: 1px solid #e1e1e1;
        color: #131313;
        font-size: 14px;
        font-weight: 500;
        height: 40px;
        padding: 0 16px;
      }

      .icon-button {
        height: 25px;
        position: relative;
        width: 25px;
      }

      .more-icon {
        color: #131313;
        font-size: 20px;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  .payment-method,
  .transaction-history {
    margin-bottom: 30px;
  }
  @media (min-width: 960px) {
    position: relative;

    .header-more {
      display: none;
    }

    .sub-menu {
      display: block;
    }

    .desktop-design {
      display: block;
    }

    .mobile-design {
      display: none;
    }
  }
}

.billing {
  .title {
    border-bottom: 1px solid $color-border-light;
    color: #b5b5b5;
    font-size: $size-text;
    font-weight: 400;
    margin-top: 15px;
    padding: 5px 20px;
    text-transform: uppercase;
    @media (max-width: 360px) {
      font-size: 13px;
    }
  }

  .billing-card {
    align-items: center;
    border-radius: 6px;
    box-shadow: 0 -1px 6px rgba(0, 0, 0, 0.19) !important;
    display: flex;
    flex-direction: column;
    max-height: 177px;
    padding: 25px 0 10px;
    position: relative;
    text-align: center;

    .declined-symbol {
      color: #b5b5b5;
      font-size: 26px;
    }

    .label {
      color: $color-text;
      font-size: $size-label;
      margin-top: 15px;
    }
  }

  // .cards,
  // .offer-wrapper {
  //   padding: 22px;
  //   @media (max-width: 1100px) {
  //     padding: 6px;
  //   }
  //   @media (max-width: 1400px) {
  //     padding: 12px;
  //   }
  // }

  .offer-card {
    background-position: top;
    background-repeat: no-repeat;
    border: 1px solid #e9e9e9;
    margin-bottom: 20px;
  }

  .content {
    display: flex;
    justify-content: space-between;
    padding: 100px 20px 20px;
    text-align: center;
  }

  .bold-text {
    color: $color-text;
    font-size: 25px;
    font-weight: bold;
  }

  .normal-text {
    color: $color-text;
    font-size: $size-menu;
  }

  .payment-method {
    border-bottom: 1px solid $color-border-light;

    .expiration {
      padding: 14px 20px;
    }
  }

  .single-invoice {
    border-bottom: 1px solid $color-border-light;
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;

    .content {
      display: block;
      padding: 0;
      text-align: left;
    }

    .light-text {
      color: #b5b5b5;
      font-size: $size-label;
    }

    .dark-text {
      color: $color-text;
      font-size: $size-menu;
    }
  }
}

.payment-methods-page {
  .paper {
    padding-right: 24px;
    position: relative;
    @media (max-width: 768px) {
      padding-right: 0;
    }
  }

  .sticky-header {
    background-color: #fff;
    height: 35px;
    padding: 15px;
    position: sticky;
    top: 0;
    z-index: 999;
  }

  .content {
    max-height: 100%;
    overflow: auto;
    padding: 0 15px 15px;
  }

  .mobile-content {
    padding: 20px;

    .card-single {
      border-radius: 6px;
      box-shadow: none;
      filter: drop-shadow(0px -1px 6px rgba(0, 0, 0, 0.19));
      margin-bottom: 30px;
      padding: 15px 15px 20px 24px;

      .card-header {
        align-items: center;
        display: flex;
        justify-content: space-between;
        padding-bottom: 20px;
      }

      img {
        vertical-align: middle;
      }

      .icon-button {
        padding: 6px;
      }

      .fav-icon {
        padding: 0;

        .icon-button {
          padding: 0;
        }

        .filled {
          color: $color-primary;
        }
      }

      .more-icon {
        color: $color-primary;
      }

      .card-title {
        font-size: 13px;
        font-weight: 600;
      }

      .info {
        color: #131313;
        font-size: 13px;
      }

      .card-detail {
        align-items: center;
        display: flex;
        justify-content: space-between;
        padding-right: 30px;
      }
    }
  }

  .page-title {
    color: #131313;
    font-size: 24px;
    margin-bottom: 30px;
  }

  .close-icon {
    position: absolute;
    right: 5px;
    top: 5px;
  }

  .payment-table {
    .table-head {
      th {
        background-color: #f2f2f2;
        height: 40px;
        padding: 0 16px;
        color: #131313;
      }
    }

    .table-body {
      td {
        font-size: 13px;
        height: 60px !important;
        padding: 0 16px;
      }

      .label {
        margin-left: 0;
      }
    }

    .fav-icon {
      height: 28px;
      width: 28px;

      .empty {
        color: #d9d9d9;
      }

      .filled {
        color: $color-primary;
      }
    }

    .action {
      align-items: center;
      display: flex;
      justify-content: space-between;

      .btn {
        text-transform: capitalize;
      }
    }

    .cards-logo {
      img {
        max-width: 50%;
      }
    }

    .card-info {
      color: #131313;

      .card-title {
        font-size: 13px;
        font-weight: 600;
      }

      .info {
        font-size: 13px;
      }
    }
  }

  .new-payment {
    margin-top: 32px;
    text-align: right;

    .label {
      color: #fff;
      text-transform: none;
    }
  }
}

.add-new-card {
  label {
    word-break: break-all;
    font-size: 15px;
  }

  .backdrop {
    background-color: #fff;
  }

  .paper {
    background: #fbfbfb;
    border: 1px solid #ececec;
    border-radius: 4px;
    // box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    // max-width: 517px;
    padding: 10px 20px 20px;
    // width: 517px;
  }

  .header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    @media (max-width: 768px) {
      padding-top: 20px;
    }

    .title {
      color: #131313;
      font-size: 24px;
      @media (max-width: 768px) {
        font-size: 22px;
      }
    }
  }

  .modal-content {
    .container {
      max-width: 100%;
    }
  }

  .switch {
    color: #707070;
    font-size: 13px;
    margin-top: 8px;
  }

  .text-field {
    height: 65px;
    margin-top: 0;

    .input-wrapper {
      background-color: #f9f9f9;

      &::before {
        border-bottom: 1px solid #ccc;
      }
    }

    .input {
      padding-left: 10px;
    }

    .MuiFormHelperText-contained {
      &.Mui-error {
        display: contents;
      }
    }
  }

  .address-title {
    color: #131313;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px;
    margin-top: 35px;
    padding-left: 10px;
  }

  .action-buttons {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    width: 100%;
    @media (max-width: 768px) {
      padding-bottom: 20px;
    }

    .text-primary {
      color: #707070;
      font-size: 13px;
      text-transform: capitalize;
    }

    .contained-primary .label {
      color: black !important;
      text-transform: capitalize;
    }
  }

  &.set-primary {
    .modal-content {
      margin: 20px 0 10px;

      .details {
        color: #131313;
        font-size: 14px;
        font-weight: 500;

        .bold-text {
          font-weight: 600;
        }
      }
    }
    @media (max-width: 768px) {
      .action-buttons {
        margin-top: 120px;
      }
    }
  }
  @media (max-height: 800px) {
    .switch {
      margin-top: 0;
    }

    .address-title {
      margin-bottom: 0;
      margin-top: 20px;
    }
  }
}

.subscription-plans-page {
  .paper {
    overflow: hidden;
    position: relative;
    @media (max-width: 768px) {
      overflow-y: auto;
    }
  }

  .tabs-wrapper {
    margin-bottom: 25px;
    margin-top: 25px;
    .indicator {
      display: none;
    }

    .tab-inner-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .billing-tabs {
    background-color: white;
    border: 2px solid rgb(45, 184, 232);
    color: rgb(45, 184, 232);
    font-size: 18px;
    height: 45px;
    min-height: 0;
    padding: 10px 40px;
    font-weight: bold;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto !important;
    cursor: pointer;

    &.selected {
      background-color: rgb(45, 184, 232);
      border: 1px solid rgb(45, 184, 232);
      color: #fff;
      font-weight: bold;
    }
  }

  .tab-container {
    @media (max-width: 768px) {
      padding-top: 0px;
    }
  }

  .page-title {
    color: #131313;
    font-size: 24px;
    margin-bottom: 30px;
  }

  .content {
    height: 100%;
  }

  .close-icon {
    position: absolute;
    right: 5px;
    top: 5px;
  }

  .left-content {
    background-color: #fff;
    height: 100%;
    // overflow-y: auto;
    padding: 0 15px 15px 15px !important;
    width: 75%;
    @media (max-width: 768px) {
      height: auto;
      width: 100%;
    }

    .page-title {
      background-color: #fff;
      height: 40px;
      left: 0;
      padding-top: 17px;
      position: sticky;
      top: 0;
      width: 100%;
      z-index: 999;
      @media (max-width: 768px) {
        position: inherit;
      }
    }

    .container {
      align-items: center;
      justify-content: space-around;

      @media (max-width: 768px) {
        flex-direction: column;
      }
    }

    .slick-track {
      display: flex;
    }

    .single-card {
      border: 1px solid #e4e4e4;
      border-radius: 2px;
      box-shadow: none;
      cursor: pointer;
      max-width: 31.5%;
      padding: 21px 18px 18px;
      text-align: center;
      width: 100%;
      @media (max-width: 768px) {
        max-width: 100%;
        padding: 21px 0 18px !important;
      }

      &:hover {
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
      }

      .plan-title {
        color: #343434;
        font-size: 24px;
        margin-bottom: 15px;
      }

      img {
        margin: 0 auto;
        width: 95px;
      }

      .price-tag {
        color: #343434;
        font-size: 26px;
        font-weight: 600;
        margin-bottom: 18px;
        margin-top: 12px;
      }

      .features {
        .single {
          color: #343434;
          font-size: 14px;
          justify-content: center;
          margin-bottom: 5px;
          padding: 0;

          &.features-main {
            font-weight: 500;
          }
        }
      }

      .switch {
        color: #343434;
        font-size: 13px;
        font-weight: 600;
        margin: 25px 0 30px;

        svg {
          height: 8px;
          width: 8px;

          path {
            fill: $color-primary;
          }
        }

        .custom-switch {
          height: 39px !important;
          width: 54px !important;

          .thumb {
            height: 12px !important;
            width: 12px !important;
          }
        }
      }

      .text-field {
        margin-bottom: 25px;
        @media (max-width: 768px) {
          width: 80%;
        }

        .input-wrapper {
          background-color: #f2f2f2;
          border-radius: 0;
          padding-left: 8px;

          &::before {
            border-bottom: 1px solid #ccc;
          }
        }

        .label.shrink {
          transform: translate(12px, 2px) scale(0.75);
        }
      }

      .contained-primary {
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16) !important;
      }

      .contained-primary .label {
        color: black;
        text-transform: capitalize;
      }

      .container-white .label {
        color: black;
        text-transform: capitalize;
      }

      &.featured {
        background: linear-gradient(#ffc70d 0%, #22c1c3 100%);
        border: none;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.39);
        position: relative;

        .popular-tag {
          align-items: center;
          background-color: #fbfbfb;
          color: #343434;
          display: flex;
          font-size: 14px;
          height: 30px;
          left: 50%;
          padding: 0 25px;
          position: absolute;
          top: 0;
          transform: translate(-50%, -20%);
          white-space: nowrap;
        }

        .plan-title {
          color: #fff;
          font-weight: 700;
          margin-top: 18px;
        }

        .price-tag {
          color: #fff;
          font-weight: 700;
        }

        .features .single {
          color: #fff;
        }

        .custom-switch {
          height: 41px !important;

          .track {
            background-color: #fff;
            border: none;
            opacity: 1;
          }
        }

        .switch {
          color: #fff;

          svg path {
            fill: #fff;
          }
        }

        .contained-white {
          background-color: #fff;

          .label {
            color: black !important;
          }
        }
      }
    }

    .btn-wrapper {
      text-align: right;

      .outlined {
        text-transform: none;
      }
    }
    @media (max-height: 800px) {
      padding-bottom: 8px !important;

      .tab-container {
        margin-bottom: 15px;
        padding-top: 0;
      }

      .single-card {
        padding: 16px 18px 14px;

        .switch {
          margin: 10px 0 12px;
        }

        .features .single {
          margin-bottom: 0;
        }

        .price-tag {
          font-size: 22px;
          margin-bottom: 10px;
          margin-top: 6px;
        }

        .text-field {
          margin-bottom: 15px;
        }
      }
    }
    @media (max-height: 550px) {
      .single-card .features {
        display: none;
      }
    }
  }

  .right-content {
    background-color: #f2f2f2;
    height: 100%;
    padding: 15px !important;
    width: 25%;
    @media (max-width: 768px) {
      height: auto;
      width: 100%;
    }

    .tweet-embed {
      margin: 100px 0;
      @media (max-width: 768px) {
        margin: 40px 0;
      }
    }

    img {
      max-width: 100%;
    }
  }

  &.order-details {
    .left-content {
      padding: 15px 25px !important;
      width: 60%;

      .mobile {
        display: none;
      }
      @media (max-width: 768px) {
        width: 100%;

        .mobile {
          display: flex;
          width: 100%;

          .banner-image {
            display: block;
            margin-right: 15px;
            min-width: 48px;
          }
        }
      }
    }

    .right-content {
      height: 100%;
      overflow-y: auto;
      width: 40%;
      @media (max-width: 768px) {
        height: auto;
        padding-top: 0 !important;
        width: 100%;
      }
    }

    .banner-image {
      align-items: center;
      background: linear-gradient(#ffc70d 0%, #22c1c3 100%);
      border-radius: 50%;
      display: flex;
      height: 88px;
      justify-content: center;
      overflow: hidden;
      width: 88px;
      @media (max-width: 768px) {
        height: 48px;
        width: 48px;
      }

      img {
        width: 100%;
      }
    }

    .offer-banner-section {
      width: 15%;
      @media (max-width: 768px) {
        display: none;
      }
    }

    .offer-info {
      width: 85%;
      @media (max-width: 768px) {
        width: 100%;
        margin-left: 10px;
        .title-offer {
          display: none !important;
        }
      }
    }

    .plan-title {
      color: $color-primary;
      font-size: 24px;
      font-weight: 700;
      margin-bottom: 10px;
    }

    .price-tag {
      color: #131313;
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 15px;

      .small-text {
        font-size: 14px;
        font-weight: 500;
        margin-left: 6px;
        opacity: 0.6;
      }
    }

    .features {
      margin-bottom: 35px;

      .single {
        color: #131313;
        font-size: 14px;
        justify-content: flex-start;
        margin-bottom: 5px;
        opacity: 0.6;
        padding: 0;
      }
    }

    .coupon-title {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-bottom: 6px;

      h5 {
        color: #343434;
        font-size: 14px;
      }
    }

    .after-coupon-amount {
      align-items: center;
      border-bottom: 1px solid $color-primary;
      border-top: 1px solid $color-primary;
      display: flex;
      justify-content: space-between;
      padding: 10px 0;

      h5 {
        color: #131313;
        font-size: 16px;
        font-weight: 700;
      }
    }

    .coupon-applied {
      margin-bottom: 50px;
    }

    .payment-title h5 {
      color: #343434;
      font-size: 14px;
      font-weight: 500;
    }

    .payment-detail {
      align-items: center;
      border-bottom: 1px solid #e1e1e1;
      border-top: 1px solid #c2c2c2;
      display: flex;
      justify-content: space-between;
      padding: 10px 0;

      h5 {
        color: #131313;
        font-size: 14px;
      }

      .text-primary .label {
        font-weight: 700;
        text-transform: none;
      }
    }

    .contained-primary .label {
      color: #fff;
      text-transform: none;
    }

    .new-card-detail {
      .switch {
        color: #707070;
        font-size: 13px;
        margin-top: 8px;
      }

      .text-field {
        height: 65px;
        margin-top: 0;

        .input-wrapper {
          background-color: #f9f9f9;

          &::before {
            border-bottom: 1px solid #ccc;
          }
        }

        .input {
          padding-left: 10px;
        }

        .MuiFormHelperText-contained {
          &.Mui-error {
            display: contents;
          }
        }
      }

      .address-title {
        color: #131313;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 10px;
        margin-top: 35px;
        padding-left: 10px;
      }
    }

    .in-right {
      padding-top: 20px;
      @media (max-width: 768px) {
        background-color: #fff;
        padding: 20px;
      }
    }

    .change-payment {
      margin-top: 12px;
      width: 98%;

      label {
        word-break: break-all;
        font-size: 15px;
      }
    }

    .action-buttons {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-top: 30px;
      width: 100%;

      .text-primary {
        color: #707070;
        font-size: 13px;
        text-transform: capitalize;
      }

      .contained-primary .label {
        color: #fff;
        text-transform: capitalize;
      }
    }
  }

  &.enable-autorecharge {
    .container {
      width: 100%;
    }

    .close-icon {
      z-index: 9999;
    }

    .left-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 35px !important;
      @media (max-width: 768px) {
        padding-right: 30px !important;
      }
    }

    .page-title {
      margin-bottom: 60px;
      position: inherit;
      @media (max-width: 768px) {
        margin-bottom: 20px;
      }
    }

    .page-detail {
      color: #131313;
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 34px;
    }

    .input-title {
      color: #131313;
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 12px;
    }

    .text-field {
      height: 50px;
      margin-top: 0;

      .input-wrapper {
        background-color: #f9f9f9;

        &::before {
          border-bottom: 1px solid #ccc;
        }
      }

      .input {
        padding-left: 10px;
      }
    }

    .action-buttons {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-top: 70px;
      width: 100%;

      .text-primary {
        color: #707070;
        font-size: 13px;
        text-transform: capitalize;
      }

      .contained-primary .label {
        color: #fff;
        text-transform: capitalize;
      }
    }
  }
}

.learn-how-to-resolve-link {
  font-size: 14px;
}

.transaction-history-page {
  .paper {
    position: relative;
  }

  .sticky-header {
    background-color: #fff;
    height: 35px;
    padding: 15px;
    position: sticky;
    top: 0;
    z-index: 999;
  }

  .content {
    max-height: 100%;
    overflow: auto;
    padding: 0 15px 15px;
  }

  .page-title {
    color: #131313;
    font-size: 24px;
    margin-bottom: 30px;
  }

  .close-icon {
    position: absolute;
    right: 5px;
    top: 5px;
  }

  .table-container {
    box-shadow: none;
  }

  .cards-table {
    border: none;

    .table-head {
      background-color: #f2f2f2;
      border-radius: 5px 0 0 5px;
      height: 40px;

      th {
        border: none;
        padding: 0 16px;

        button {
          min-width: 0;
          padding: 0;

          &.on-focus {
            .drop-arrow {
              transform: rotate(180deg);
            }
          }
        }
      }

      .drop-arrow {
        cursor: pointer;
      }
    }

    .table-body {
      tr {
        height: 32px;
        padding: 0;
      }

      td {
        border-bottom: 1px solid #e1e1e1;
        color: #131313;
        font-size: 12px;
        font-weight: 500;
        height: 32px;
        padding: 0 16px;
      }

      .icon-button {
        height: 25px;
        position: relative;
        width: 25px;
      }

      .more-icon {
        color: #131313;
        font-size: 20px;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
      }

      svg {
        font-size: 20px;
        height: 16px;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 16px;

        path {
          fill: $color-primary;
        }
      }
    }
  }
}

.logs-list-page {
  .paper {
    position: relative;
  }

  .error-banner {
    color: rgb(97, 26, 21);
    background-color: rgb(255, 244, 229);
    padding: 0px 20px;
  }

  .sticky-header {
    background-color: #fff;
    height: 35px;
    padding: 15px 25px;
    position: sticky;
    top: 0;
    z-index: 999;
  }

  .top-section {
    align-items: center;
    display: flex;
    padding: 25px;

    .download {
      text-align: right;

      .icon-button {
        height: 40px;
        width: 40px;
      }

      .icon {
        color: $color-primary;
        font-size: 28px !important;
        margin: 0 !important;
      }
    }

    &.mobile-filter {
      display: none;
    }
    @media (max-width: 768px) {
      &.mobile-filter {
        display: flex;
      }

      &.desktop-filter {
        display: none;
      }
    }
  }

  .date-picker {
    background-color: #f2f2f2;
    margin-bottom: 0;
    margin-top: 0;

    // input {
    //   color: #131313;
    //   font-size: 13px;
    //   padding-left: 6px;

    //   &::placeholder {
    //     color: #131313 !important;
    //   }
    // }

    button {
      font-size: 18px;
      padding: 8px;

      svg {
        height: 18px;
        width: 18px;
      }
    }
  }

  .select-wrapper {
    background-color: #f2f2f2;
    height: 30px;

    .select {
      color: #131313;
      font-size: 13px;
      padding-left: 8px;
    }
  }

  .select-styled-button {
    align-items: center;
    background-color: #f2f2f2;
    border-bottom: 1px solid #707070;
    border-radius: 0;
    color: #999;
    display: flex;
    font-size: 13px;
    height: 30px;
    justify-content: space-between;
    padding: 0 8px !important;
    text-transform: none;
    width: 100%;
  }

  .search-button {
    box-shadow: none;
    height: 30px;
    min-width: 0;
    padding: 0;
    width: 35px;

    .label {
      color: #fff;
      font-size: 18px;
    }
  }

  .outlined {
    box-shadow: none;
    height: 30px;
    min-width: 0;
    padding: 0 11px;
    text-transform: none;
  }

  .content {
    max-height: 100%;
    overflow: auto;
    padding: 0 25px 25px;
  }

  .page-title {
    color: #131313;
    font-size: 24px;
    margin-bottom: 30px;
  }

  .close-icon {
    position: absolute;
    right: 5px;
    top: 5px;
  }
}

.status-select-menu {
  min-width: 170px;

  li {
    height: 38px;
    padding: 0 0 0 12px;

    .label span {
      color: #131313;
      font-size: 13px;
      font-weight: 500;
    }
  }
}

.filter-select-menu {
  min-width: 170px;
  width: 200px;

  li {
    height: 38px;
    padding: 0 0 0 12px;

    .label span {
      color: #131313;
      font-size: 13px;
      font-weight: 500;
    }
  }
}

.logs-table-tooltip {
  background-color: transparent !important;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  margin: 0 !important;
  padding: 0 !important;

  .content {
    background-color: #f2f2f2;
    padding: 6px;
  }

  p {
    color: #131313;
    font-size: 13px;
  }
}

.react-grid-logs-table {
  .react-grid-Container {
    -webkit-overflow-scrolling: auto;
  }

  .react-grid-Canvas {
    width: 100% !important;
  }

  .react-grid-Grid {
    border: none !important;
    border-radius: 5px 5px 0 0;
    min-height: calc(100vh - 170px) !important;
  }

  .react-grid-Main {
    outline: none !important;
    // padding-left: 20px;
    // padding-right: 20px;
  }

  .react-grid-HeaderRow {
    background-color: #f2f2f2;
    border: none !important;
    height: 50px;
    overflow: hidden;

    div {
      align-items: center;
      color: #131313;
      display: flex;
      font-size: 13px;
      font-weight: 600;
      height: 50px;
      padding: 0 0 0 8px;
    }
  }

  .react-grid-Row {
    border-bottom: 1px solid #f2f2f2;
    height: 50px !important;

    .react-grid-Cell {
      font-size: 13px;
      font-weight: 500;
      height: 50px !important;
      line-height: 1.3;
      padding-right: 0;

      p {
        font-size: 13px;
        font-weight: 500;
        line-height: 1.3;
      }
    }

    .react-grid-Cell__value {
      border-right: 1px solid #f2f2f2;
    }

    .service-column {
      padding-left: 10px;
      padding-right: 10px;

      p {
        font-size: 13px;
        line-height: 1.3;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .message-column {
      display: flex;

      .icon {
        font-size: 12px !important;
        height: 16px !important;
        margin-left: 0;
        margin-right: 10px;
        width: 16px !important;
      }
    }
  }
}

.fab {
  &.payment {
    position: relative !important;
    bottom: 4px !important;
    font-size: 0.5rem;
    width: 30px !important;
    height: 30px !important;
    min-height: 30px !important;
    right: -10px;
    z-index: 1000;

    .material-icons {
      font-size: 27px;
    }
  }
}

.MuiTooltip-tooltip {
  font-size: 0.75rem !important;
}

.current-plan {
  line-height: 34px;
  height: 34px;
  color: #ffd110 !important;
  font-weight: 500 !important;
  font-size: 0.875rem !important;
  background: #fff;
}

.subscribe-btn {
  padding: 2px !important;
}

.link-text {
  color: #22c1c3 !important;
  cursor: pointer;
}

.color-primary {
  font-weight: 600 !important;
}

.text-white-bold {
  color: white;
  font-weight: 600 !important;
}

.input-wrapper-coupan {
  background-color: #f2f2f2 !important;

  .input {
    color: #131313;
    font-size: 13px;
    padding: 8px 12px 7px;
  }

  .input::placeholder {
    color: #999;
    text-align: center !important;
    width: 100% !important;
    font-size: 13px;
    opacity: 1;
  }
}

.text-color {
  color: #ffc70d !important;
  font-weight: bold !important;
}

.caption {
  color: #fff;
  font-size: 13px !important;
}

.card-decline-title {
  color: #ff552c;
}

.card-decline-text-wrapper {
  background-color: #ffe2da;
  color: #ff552c !important;
  padding: 15px;
  border-radius: 5px;
}
