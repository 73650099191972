@import "./../../base/variables";

.header-services {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16) !important;
  max-width: 1024px;
  position: fixed !important;
  top: 0;
  width: 100%;
  @media (min-width: 1400px) {
    width: 100%;
  }

  &.without-modal {
    .right-action {
      .icon-button {
        display: none;
      }
    }
  }

  .toolbar {
    align-items: center;
    display: flex;
    height: 60px;
    padding: 0 0 0 15px;
    white-space: nowrap;
  }

  .icon-button {
    color: #fff;
    padding: 5px;
  }

  .title {
    color: #fff;
    font-size: $size-menu;
    font-weight: 400;
    margin-left: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    a {
      text-decoration: none;
      color: #fff;
      font-size: 16px;
      font-weight: 400;
      @media (min-width: 960px) {
        text-transform: capitalize;
      }
    }

    a:last-child {
      margin-right: 8px;
    }
  }

  .right-action {
    margin-left: auto;
    display: flex;

    .icon-button {
      color: #fff;
      position: relative;
      left: 0;
    }
  }

  .header-button {
    color: #fff;
    padding-right: 15px;
  }

  .search-bar {
    width: 100%;
    min-height: 45px !important;

    .form-control {
      flex-grow: 1;
      justify-content: center;
      border-radius: 30px;
      height: 32px;
      border: 1px solid #707070;
    }

    .cancel-btn {
      font-size: 12px;
      margin-left: 20px;
      @media (max-width: 960px) {
        margin-left: 5px;
      }
    }

    .search {
      &::placeholder {
        color: #b5b5b5;
        font-size: $size-menu;
      }

      input::-webkit-input-placeholder {
        line-height: normal !important;
      }

      .icon-button {
        color: #b5b5b5;
        padding: 0;
      }
    }

    .fieldset {
      border: none;
      max-height: 42px;
      top: 0;
    }

    .input {
      height: 42px;
      padding-bottom: 0;
      padding-left: 14px;
      padding-top: 0;
    }
  }
}

.add-new-sync-btn-container {
  width: 35%;
  @media (max-width: 992px) {
    width: max-content;
  }

  .add-new-sync-btn {
    background-color: #2db8e8 !important;
    display: flex;
    justify-content: flex-start;

    span {
      color: white !important;
      text-transform: capitalize;
    }
  }

  .add-new-sync-btn-disabled {
    background-color: #bebbbb !important;
    display: flex;
    justify-content: flex-start;

    span {
      color: white !important;
      text-transform: capitalize;
    }
  }
}

.w-5 {
  width: 5% !important;
}

.w-22 {
  width: 22.5% !important;
}
