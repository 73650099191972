.number-steps {
  display: flex;

  ul {
    display: flex;
    list-style: none;
    padding: 0;
    margin-left: auto;
    margin-right: auto;
    position: relative;

    li {
      position: relative;
      display: flex;
      justify-content: flex-end;

      &.small-step-bar:first-child {
        width: 25px;
      }

      &.small-step-bar:first-child:before {
        display: none;
      }

      &.small-step-bar {
        width: 70px;
      }

      &.small-step-bar:before {
        content: "";
        position: absolute;
        height: 3px;
        right: 0;
        top: 50%;
        transform: translate(0, -1.5px);
        width: 70px;
        background-color: #2db8e8;
      }

      &.large-step-bar:first-child {
        width: 25px;
      }

      &.large-step-bar:first-child:before {
        display: none;
      }

      &.large-step-bar {
        width: 135px;
      }

      &.large-step-bar:before {
        content: "";
        position: absolute;
        height: 3px;
        right: 0;
        top: 50%;
        transform: translate(0, -1.5px);
        width: 135px;
        background-color: #2db8e8;
      }

      &.number-step-progress:before {
        content: "";
        position: absolute;
        height: 3px;
        right: 0;
        top: 50%;
        transform: translate(0, -1.5px);
        background-color: #2db8e8;
      }

      &.done:before {
        content: "";
        position: absolute;
        height: 3px;
        right: 0;
        top: 50%;
        transform: translate(0, -1.5px);
        background-color: #2db8e8;
      }

      &.disabled:before {
        content: "";
        position: absolute;
        height: 3px;
        right: 0;
        top: 50%;
        transform: translate(0, -1.5px);
        background-color: #dbdbdb;
      }

      & > span.large-step {
        cursor: pointer;
        height: 25px;
        width: 25px;
        background-color: #dbdbdb;
        border-radius: 50%;
        text-align: center;
        position: relative;
        z-index: 2;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      & > span.small-step {
        font-size: 10px;
        cursor: pointer;
        height: 12px;
        width: 12px;
        background-color: #dbdbdb;
        border-radius: 50%;
        text-align: center;
        position: relative;
        z-index: 2;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &.current > span {
        background-color: #2db8e8;
      }

      &.done > span {
        background-color: #2db8e8;
      }

      .number {
        color: #fff;
      }
    }
  }
}
