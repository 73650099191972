.card-info-container {
  border: 1px solid #e1e1e1;
  border-radius: 8px;

  .card-info-title-container {
    color: white;
    font-size: 16px;
    font-weight: 600;
    border-radius: 8px 8px 0 0;
    border-bottom: 1px solid #e1e1e1;
    padding: 12px 16px;
    display: flex;
    align-items: center;
  }

  .card-info-content-container {
    word-break: break-word;
    padding: 12px 18px;
    position: relative;
    overflow: hidden;

    .card-info-content-overlay {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0px;
      left: 0;
      z-index: 100;
    }
  }
}

.sub-card-info-container {
  border: 1px solid #e1e1e1;
  border-radius: 17px;
  .sub-card-info-title-container {
    color: #666666;
    font-size: 13px;
    font-weight: 600;
    border-radius: 17px 17px 0 0;
    padding: 10px 16px;
    display: flex;
    align-items: center;
  }

  .disabled-title {
    color: #d9d9d9;
  }

  .sub-card-info-content-container {
    border-top: 1px solid #e1e1e1;
    padding: 10px 16px;
  }
}
