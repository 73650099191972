/*--------------------
  Form
  --------------------*/
.form {
  fieldset {
    border: none;
    padding: 0;
    padding: 10px 0;
    position: relative;
    clear: both;

    &.fieldset-expiration {
      float: left;
      width: 60%;

      & .select {
        width: 84px;
        margin-right: 12px;
        float: left;
      }
    }

    &.fieldset-ccv {
      clear: none;
      float: right;
      width: 86px;
    }

    label {
      display: block;
      text-transform: uppercase;
      font-size: 11px;
      color: hsla(0, 0, 0, 0.6);
      margin-bottom: 5px;
      font-weight: bold;
    }

    input,
    .select {
      width: 100%;
      height: 38px;
      color: hsl(0, 0, 20);
      padding: 10px;
      border-radius: 5px;
      font-size: 15px;
      outline: none !important;
      border: 1px solid hsla(0, 0, 0, 0.3);
      box-shadow: inset 0 1px 4px hsla(0, 0, 0, 0.2);

      &.input-cart-number {
        width: 82px;
        display: inline-block;
        margin-right: 8px;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .select {
      position: relative;

      &::after {
        content: "";
        border-top: 8px solid #222;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        position: absolute;
        z-index: 2;
        top: 14px;
        right: 10px;
        pointer-events: none;
      }

      select {
        appearance: none;
        position: absolute;
        padding: 0;
        border: none;
        width: 100%;
        outline: none !important;
        top: 6px;
        left: 6px;
        background: none;

        :-moz-focusring {
          color: transparent;
          text-shadow: 0 0 0 #000;
        }
      }
    }
  }
}

/*--------------------
  Checkout
  --------------------*/
.checkout {
  @media (max-width: 768px) {
    margin: 0px;
    width: 100%;
    padding: 25px 15px;
  }
  margin: 150px auto 30px;
  overflow-y: auto;
  position: relative;
  width: 460px;
  background: white;
  border-radius: 15px;
  padding: 160px 45px 30px;
  box-shadow: 0 10px 40px hsla(0, 0, 0, 0.1);
}

/*--------------------
  Credit Card
  --------------------*/
.credit-card-box {
  @media (max-width: 768px) {
    display: none;
  }
  perspective: 1000;
  z-index: 200000;
  width: 400px;
  height: 280px;
  position: absolute;
  top: 7%;
  left: 50%;
  transform: translateX(-50%);

  &:hover .flip,
  &.hover .flip {
    transform: rotateY(180deg);
  }

  .flip-card {
    transform: rotateY(180deg);
  }

  .front,
  .back {
    width: 400px;
    height: 250px;
    border-radius: 15px;
    backface-visibility: hidden;
    background: linear-gradient(135deg, #e2af06, #05aeb1);
    position: absolute;
    color: #fff;
    top: 0;
    left: 0;
    text-shadow: 0 1px 1px hsla(0, 0, 0, 0.3);
    box-shadow: 0 1px 6px hsla(0, 0, 0, 0.3);

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: url("http://cdn.flaticon.com/svg/44/44386.svg") no-repeat
        center;
      background-size: cover;
      opacity: 0.05;
    }
  }

  .flip {
    transition: 0.6s;
    transform-style: preserve-3d;
    position: relative;
  }

  .flip-card {
    transition: 0.6s;
    transform-style: preserve-3d;
    position: relative;
  }

  .logo {
    position: absolute;
    top: 9px;
    right: 20px;
    width: 60px;

    svg {
      width: 100%;
      height: auto;
      fill: #fff;
    }
  }

  .front {
    z-index: 2;
    transform: rotateY(0deg);
  }

  .back {
    transform: rotateY(180deg);

    .logo {
      top: 185px;
    }
  }

  .chip {
    position: absolute;
    width: 60px;
    height: 45px;
    top: 20px;
    left: 20px;
    background: linear-gradient(
      135deg,
      hsl(269, 54%, 87%) 0%,
      hsl(200, 64%, 89%) 44%,
      hsl(18, 55%, 94%) 100%
    );
    border-radius: 8px;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      border: 4px solid hsla(0, 0, 50, 0.1);
      width: 80%;
      height: 70%;
      border-radius: 5px;
    }
  }

  .strip {
    background: linear-gradient(135deg, hsl(0, 0, 25%), hsl(0, 0, 10%));
    position: absolute;
    width: 100%;
    height: 50px;
    top: 30px;
    left: 0;
  }

  .number {
    position: absolute;
    margin: 0 auto;
    top: 103px;
    left: 19px;
    font-size: 38px;
  }

  label {
    font-size: 10px;
    letter-spacing: 1px;
    text-shadow: none;
    text-transform: uppercase;
    font-weight: normal;
    opacity: 0.5;
    display: block;
    margin-bottom: 3px;
  }

  .card-holder,
  .card-expiration-date {
    position: absolute;
    margin: 0 auto;
    top: 180px;
    left: 19px;
    font-size: 22px;
    text-transform: capitalize;
  }

  .card-expiration-date {
    text-align: right;
    left: auto;
    right: 20px;
  }

  .ccv {
    height: 36px;
    background: #fff;
    width: 91%;
    border-radius: 5px;
    top: 110px;
    left: 0;
    right: 0;
    position: absolute;
    margin: 0 auto;
    color: #000;
    text-align: right;
    padding: 10px;

    label {
      margin: -25px 0 14px;
      color: #fff;
    }
  }
}

.the-most {
  position: fixed;
  z-index: 1;
  bottom: 0;
  left: 0;
  width: 50vw;
  max-width: 200px;
  padding: 10px;

  img {
    max-width: 100%;
  }
}

.payment-card-paper {
  background-color: transparent !important;
  box-shadow: none !important;
  overflow: hidden !important;
}
