.app-wrapper,
body,
html {
  background-color: #fff;
  font-family: "Poppins", sans-serif;
  height: 100%;
  margin: 0;
  overflow-x: hidden;
  padding: 0;
}

.btn {
  border-radius: 0;
  box-shadow: none;

  @media (max-width: 768px) {
    font-size: 12px !important;
  }

  &.contained-primary {
    padding: 8px 16px;

    .label {
      color: black;
    }
  }

  &.text-primary:hover {
    .label {
      text-shadow: 0.5px 1px #ffd110;
    }
  }

  .label {
    text-transform: none;
  }
}

.app-wrapper {
  overflow: hidden;

  .logo-wrapper {
    align-items: center;
    display: flex;
    margin-bottom: 70px;

    h3 {
      color: #067302;
      display: none;
      font-size: 26px;
      font-weight: 500;
      margin-left: 20px;
    }

    .logo {
      display: flex;
      justify-content: center;
      margin-bottom: 0;
      width: 100%;
    }
  }

  .auth-wrapper {
    width: 100%;
    @media (min-width: 960px) {
      background-color: #fff;
      height: auto;
      height: 100vh;
      overflow: hidden;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      width: 50%;

      .logo-wrapper {
        margin-bottom: -15px;

        h3 {
          display: inline-block;
        }
      }

      .logo {
        display: flex;
        height: 33px;
        justify-content: center;
        width: 33px !important;
      }

      &.with-scroll {
        overflow: hidden;
      }
    }

    .flash-message {
      align-items: center;
      background-color: #d1f5d0;
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      padding: 5px 10px;

      p {
        font-size: 12px;
      }
    }

    &.with-scroll {
      overflow: initial;
    }
  }

  .page-wrapper {
    display: block;
    overflow-y: auto;
    padding-top: 60px;
    width: 100%;
    @media (max-width: 960px) {
      margin-bottom: 60px;

      &.create-acc {
        max-height: calc(100% - 60px);

        .header-for-all {
          background-color: #202020;
        }
      }
    }
    @media (min-width: 960px) {
      background-color: #fff;
      display: flex;
      height: 100vh;
      margin: 0 auto;
      padding-top: 0;
      width: 100%;

      &.create-acc .header-for-all {
        display: none;
      }

      &.create-acc {
        display: flex;
        overflow: hidden;
      }

      &.login {
        display: flex;
        overflow: hidden;
      }
    }
  }
  @media (max-width: 768px) {
    overflow: inherit;
    overflow-y: auto !important;
  }
}

.more-main-wrapper {
  .page-wrapper {
    @media (min-width: 960px) {
      display: flex;
      flex-direction: column;
      padding-top: 0;

      .header-more {
        left: 0;
        position: sticky !important;
        right: 0;
        top: 0;
      }

      .inner-content-wrapper {
        flex-grow: 1;
        max-width: 1024px;
        overflow-x: hidden;
        overflow-y: auto;
      }
    }
  }
}

.tab-container {
  /*padding-top: 50px !important;*/
  margin-bottom: 70px;

  .tab-content {
    margin: 20px;
  }
}

.sweet-loading {
  background: rgba(0, 0, 0, 0.1);
  // height: 2000px;
  // padding-top: 175px;
  position: absolute;
  width: 100%;
  z-index: 10000;
}

.flash-message {
  align-items: center;
  background-color: #d1f5d0;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  padding: 5px 10px;

  &.mb {
    margin-bottom: 10px;
  }

  p {
    font-size: 12px;
  }
}

.close-icon {
  cursor: pointer;
  font-size: 18px;
  position: absolute;
  right: 10px;
  top: 10px;
  color: #707070;
}

.contained-primary {
  background-color: $color-primary !important;
  color: black;
}

/*------------------------------------------------------------------
[2. Table]
*/
.table {
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  color: #000;

  @media (max-width: 768px) {
    width: 160% !important;
  }
}

.table.table-white {
  background-color: #fff;
}

.table > tbody > tr > td {
  font-weight: 300;
}

.table-striped > tbody > tr:nth-of-type(2n + 1) {
  background-color: #f6f6f6;
}

table.table td .avatar {
  margin: 0 5px 0 0;
}

table.table td h2 {
  display: inline-block;
  font-size: inherit;
  font-weight: 400;
  margin: 0;
  padding: 0;
  vertical-align: middle;
}

table.table td h2 a {
  color: #757575;
}

table.table td h2 a:hover {
  color: #00bf6f;
}

table.table td h2 span {
  color: #9e9e9e;
  display: block;
  font-size: 0.75rem;
  margin-top: 3px;
}

.scroll-table {
  position: relative;
  max-height: calc(100vh - 160px);
  // height: auto;
  min-height: 100px;
  overflow-x: hidden;
  overflow-y: hidden;
  &.have-confirm {
    height: calc(100vh - 160px - 52px);
  }
}

.scroll-table:hover {
  overflow-x: auto;
  overflow-y: auto;
}
.scroll-table::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

/* Track */
.scroll-table::-webkit-scrollbar-track {
  -webkit-border-radius: 8px;
  border-radius: 8px;
}

/* Handle */
.scroll-table::-webkit-scrollbar-thumb {
  -webkit-border-radius: 8px;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.2);
}
.scroll-table::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(0, 0, 0, 0.2);
}

.modal-main-heading {
  font-weight: 600;
  color: #707070;
}

.w-30 {
  width: 30% !important;
}

.dark-gray-button {
  background-color: #575656 !important;

  span {
    color: #ffd110 !important;
  }
}

.chip {
  background-color: #fff4c4 !important;
  border-radius: 5px !important;
}

.error-chip {
  background-color: rgb(97, 26, 21) !important;
  color: white !important;
  border-radius: 8px !important;
}

.modal-table-header {
  tr th {
    padding: 5px !important;
  }
}

.tag-chip {
  border-radius: 5px !important;
  max-width: 250px !important;
}

.table td,
.table th {
  @media (max-width: 770px) {
    padding: 2px !important;
  }
}

.rounded-button {
  display: flex;
  justify-content: center;
  align-content: center;
  background-color: transparent !important;
  color: #007bff !important;
  cursor: pointer;
}

.radio-checked {
  color: #2db8e8 !important;
}

.top-banner {
  height: 27px;
  width: 100%;
}

.one-two-way-icon {
  background-color: darkgray;
  color: white;
  border-radius: 25px;
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1px 5px 1px 5px;

  span {
    margin-bottom: 2px;
  }
}

.with-sidebar-expanded {
  margin-left: 240px !important;
  @media (max-width: 770px) {
    margin-left: 0px !important;
  }
}

.with-sync-page-sidebar-expanded {
  margin-left: 160px !important;
  @media (max-width: 770px) {
    margin-left: 0px !important;
  }
}

.with-sidebar-collapsed {
  margin-left: 80px !important;
  @media (max-width: 770px) {
    margin-left: 0px !important;
  }
}

.with-page-sub-menu-expanded {
  margin-left: 170px !important;
  @media (max-width: 770px) {
    margin-left: 0px !important;
  }
}
