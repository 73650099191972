.sync-page-notfication-banner-wrapper {
  background-color: #202020;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  padding-right: 10px;
  padding-top: 10px;
  width: calc(100% - 60px);
  @media (max-width: 992px) {
    width: 100%;
  }

  .sync-page-noti-banner {
    padding: 0px 20px;
    width: 98%;
    border-radius: 30px;
  }
}

.service-topbar-wrapper {
  background-color: #202020;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  padding-right: 10px;
  width: calc(100% - 60px);

  @media (max-width: 992px) {
    width: 100%;
  }

  .service-topbar-inner-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 50.2%;
  }

  .service-topbar-sync-button {
    width: 150px;
  }

  .disabled {
    background-color: #bebbbb !important;
    opacity: 1 !important;

    span {
      color: grey !important;
    }
  }

  .service-topbar-select-tab-text {
    margin-right: 30px;
    font-size: 14px;
  }

  .tab {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 49%;
    height: 55px;
    cursor: pointer !important;
    border-radius: 10px 10px 0px 0px;
    background-color: #e3e3e3;
    color: #bebbbb;
    font-size: 16px;
    @media (max-width: 960px) {
      font-size: 12px;
    }
  }

  .active {
    background-color: #707070;
    color: white;
  }
}
