@import "./../base/variables";

.mobile-menu {
  display: none;
  @media (max-width: 770px) {
    display: flex;
    background-color: #202020;
    width: 100%;
    bottom: 0;
    box-shadow: none;
    box-shadow: 0 -1px 6px rgba(0, 0, 0, 0.19);
    position: absolute;
    z-index: 100;
    height: 60px;
  }

  .single {
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 25%;
    color: #d9d9d9;

    svg {
      width: 26px;
      margin-bottom: 0;

      #dashboard path,
      #more,
      #service,
      #integrations,
      .outline {
        fill: #d9d9d9;
      }
    }

    .main-label {
      font-size: 12px;
      margin-top: 2px;
    }

    &.active {
      color: $color-primary;
      svg #dashboard path,
      svg #more,
      svg #service,
      svg .outline {
        fill: $color-primary;
      }

      svg .people {
        fill: $color-primary;
      }
    }

    &.dashboard {
      border-radius: 0;
      height: 38px;
      min-height: 38px;
      min-width: 38px;
      position: relative;
      width: 38px;

      img {
        bottom: 0;
        left: 0;
        max-width: 100%;
        position: absolute;
        top: 0;
      }

      &.active {
        background-color: transparent;
      }
    }
  }

  .bottom-icons {
    align-items: center;
    bottom: 0;
    display: flex;
    flex-direction: column;
    position: absolute;
  }
}
