@import "./../../base/variables";

.sync-page-wrapper {
  @media (max-width: 992px) {
    overflow: auto;
  }
  @media (max-width: 768px) {
    height: 95vh;
  }
  .services {
    @media (max-width: 992px) {
      width: max-content;
    }
  }
}

.integrations {
  padding: 20px;
  // @media (min-width: 960px) {
  //   margin-right: 36px;
  //   padding: 0;
  // }

  .desktop {
    display: block;
  }

  .integration-card {
    background-color: #2c5fd8;
    height: 190px;
    position: relative;

    .right-icon .material-icons {
      color: #fff;
      float: right;
    }

    .right-icon {
      position: absolute;
      right: 0;
      top: 6px;
    }

    .integrations-planning-center {
      left: 15px;
      position: absolute;
      right: auto;
      top: 10px;
    }

    .account {
      bottom: 12px;
      color: #fff;
      left: 16px;
      position: absolute;
    }

    .account-title {
      opacity: 0.5;
    }
  }

  .integration-card-inactive {
    background-color: #fff;
    border: 1px solid #cccccc;
    padding: 12px 22px;

    .planning-center {
      position: relative;
    }

    .pc-details {
      color: $color-text;
      font-size: 14px;
    }
  }

  .comming-soon {
    bottom: 60px;
    left: 0;
    padding: 0 14px;
    position: absolute;
    right: 0;

    h3 {
      border-bottom: 1px solid $color-border-light;
      color: $color-text;
      font-size: $size-text;
      font-weight: 400;
      padding: 4px 12px;
      text-transform: uppercase;
      @media (max-width: 360px) {
        font-size: 13px;
      }
    }

    .logos {
      align-items: center;
      display: flex;

      img {
        max-width: 100%;
      }
    }
  }
}

.integration-page {
  background-color: #fff !important;
  box-shadow: none !important;
  margin: 0 !important;
  padding-right: 20px;
  width: auto !important;

  .sub-menu {
    bottom: 0;
    display: none;
    position: fixed;
    top: 0;
  }

  .desktop-view {
    display: block;
    margin-top: 50px;
    width: 100%;
    @media (max-width: 960px) {
      margin-left: 0;
      padding-left: 10px;
    }

    .title {
      border-bottom: 1px solid #bbbbbb;
    }

    .cards {
      margin-bottom: 50px;
      margin-top: 15px;
    }

    .cards .single {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      border-radius: 10px;
      padding: 15px 20px;
      min-height: 210px;
      height: auto;

      .icon-text {
        color: white;
      }

      .box-title {
        align-items: center;
        display: flex;
        justify-content: space-between;

        .material-icons {
          cursor: pointer;
        }

        .btn {
          align-items: center;
          border-radius: 4px;
          display: flex;
          height: 30px;
          justify-content: center;
          padding: 0 10px !important;
        }

        .text-primary {
          color: #fbc02d !important;
          font-weight: 600;
        }

        .text-white {
          color: white !important;
          font-weight: 600;
        }

        .text-black {
          color: black !important;
          font-weight: 600;
        }
      }

      .content {
        color: #fff;
        font-size: 13px;
        margin-bottom: 30px;
        margin-top: 50px;
        text-align: center;

        p {
          font-size: 13px;
        }
      }

      .level-two {
        font-weight: 600;
      }

      .sync-info {
        color: #fff;
        font-size: 10px;
        // opacity: 50%;
        text-align: center;
      }

      &.mailchimp-active {
        background-color: #ffe01b;

        .content {
          color: #000000;
          margin-top: 38px;
        }

        .icon-text {
          color: #000000;
        }
      }

      &.mailchimp-inactive {
        background-color: #dbdbdb;

        .material-icons {
          color: #fff;
        }

        .content {
          color: #828282;
          margin-top: 38px;
        }
      }

      &.ccb-active {
        background-color: #257fe2;
      }

      &.ccb-inactive {
        background-color: #dbdbdb;

        .material-icons {
          color: #fff;
        }

        .content {
          color: #828282;
          margin-top: 38px;
        }
      }

      &.pastorsline-inactive {
        background-color: #dbdbdb;

        .material-icons {
          color: #fff;
        }

        .content {
          color: #828282;
          margin-top: 38px;
        }
      }

      &.bitly-inactive {
        background-color: #dbdbdb;

        .material-icons {
          color: #fff;
        }

        .content {
          color: #828282;
          margin-top: 38px;
        }
      }
    }

    .single-box {
      align-items: center;
      border: 1px solid #bbbbbb;
      border-radius: 10px;
      display: flex;
      height: 188px;
      justify-content: center;
      width: 100%;
    }
  }

  .desktop-title {
    color: #131313;
    font-size: 24px;
    margin-bottom: 25px;
  }
  // @media (min-width: 960px) {
  //   margin-left: 60px !important;
  //   position: relative;

  //   .header-more {
  //     display: none;
  //   }

  //   .sub-menu {
  //     display: block;
  //   }
  // }
}

.connect-account-modal {
  .paper {
    max-width: 500px;
  }

  .backdrop {
    background-color: #fff !important;
    opacity: 0.8 !important;
  }

  .btn {
    border-radius: 3px;
    color: #fff;
    height: 26px;
    padding: 0 10px !important;
    text-transform: capitalize;
  }

  .modal-content {
    color: #828282;
    font-size: 13px;
    padding: 0 10px;
  }

  .title {
    padding: 20px 30px;
    position: relative;
  }

  .close-icon {
    cursor: pointer;
    position: absolute;
    right: 15px;
    top: 15px;
  }

  .action {
    padding: 10px 30px 30px;
  }
}

.mc-tags-modal-header-container {
  font-size: 12px;
  font-weight: 500;
  margin-left: 25px;
}

.modal-search {
  padding: 8px 20px !important;
}

.fieldset {
  border-radius: 50px !important;
}

.services {
  @media (max-width: 770px) {
    width: max-content;
  }
}

.service-container-wrapper {
  @media (max-width: 770px) {
    width: 100% !important;
  }
}

.intial-sync-container {
  background-color: #202020;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% - 60px);

  .inital-sync-progress-bar {
    width: 95%;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.arrow-icon-wrapper {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
}

.edit-btn-container {
  position: absolute;
  right: 2%;
}

.integration-missing-alert {
  background-color: #ff5800 !important;
}
