.header-mapping-box {
  background-color: #ebebeb;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  padding: 5px 20px;
  border: 1px solid lightgray;
  margin-bottom: 10px;

  @media (max-width: 870px) {
    flex-direction: column;
  }

  .select-box {
    height: 40px;
  }

  .two-one-way-icon-container {
    right: 10%;
    position: absolute;
    @media (max-width: 870px) {
      right: 0%;
      position: relative;
    }
  }

  .header-mapping-name-container {
    width: 50%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    @media (max-width: 870px) {
      width: 100%;
      justify-content: center;
      flex-wrap: wrap;
    }
  }

  .header-mapping-select-box-container {
    width: 50%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    @media (max-width: 870px) {
      width: 100%;
      flex-wrap: wrap;
    }
  }
}

.tools-exception-body-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #e3e3e3;
  border-bottom: 1px solid #e3e3e3;
  height: 180px;
}

.tool-exception-modal-container {
  max-width: 830px !important;
  width: 100% !important;
}

.delete-pair-icon {
  font-size: 2.5rem !important;
}

.header-field-error-message {
  font-size: 12px;
}
