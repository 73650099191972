@import "./../base/variables";

.main-menu {
  height: 100vh;
  width: 60px;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #202020;
  padding-top: 20px;
  white-space: nowrap;
  box-shadow: 0 -1px 6px rgba(0, 0, 0, 0.19);
  z-index: 100;
  color: grey;
  font-size: 16px;
  overflow: hidden;
  &.hover-main-menu {
    width: 220px !important;
  }

  @media (max-width: 770px) {
    display: none;
  }

  .single {
    // background-color: transparent;

    .admin {
      color: #ffd110;
    }

    svg {
      margin-bottom: 0;

      .outline {
        fill: $color-primary;
      }
    }

    &.active {
      background-color: $color-primary;
      .admin {
        color: #000;
      }

      svg .outline {
        fill: #202020;
      }

      svg .people {
        fill: #202020;
      }
    }

    &.dashboard {
      border-radius: 50%;
      height: 54px;
      min-height: 38px;
      min-width: 38px;
      position: relative;
      width: 54px;

      img {
        bottom: 0;
        left: 0;
        max-width: 100%;
        position: absolute;
        top: 0;
      }

      &.active {
        background-color: transparent;
      }
    }
  }

  .bottom-icons {
    align-items: center;
    bottom: 20px;
    display: flex;
    flex-direction: column;
    position: absolute;
  }
}

.main-app-container {
  .main-menu {
    height: 97vh !important;
    top: 28px !important;
  }
}

.menu-text {
  color: lightgray;
}

.expanded-menu-item-container {
  width: 90% !important;
  border-radius: 10px !important;
  margin-left: 10px !important;

  &.active-menu,
  &:hover {
    background-color: rgba(211, 211, 211, 0.346) !important;
  }
}

.collapse-menu-container {
  height: 40px;
  width: 100% !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  &.active-menu,
  &:hover {
    background-color: rgba(211, 211, 211, 0.346) !important;
  }
}

.bottom-container-collapse {
  color: white;
  cursor: pointer;

  .expand-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 12px !important;
    width: 100%;
    height: 100%;
  }
}
